<template>
  <div id="coin-history">

    <table-action-row
      :filterList="listQuery"
      canFilterDate
      @onFilterChange="value => { listQuery = value; getList() }"
    />

    <el-table
      v-loading="isLoading"
      class="newsily-bo-table"
      :data="list">
      <el-table-column :label="$t('TIME')" :width="isMobile ? '150' : null">
        <template slot-scope="{row}">
          {{ parseDate(row.createdDate) }}
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('TYPE')"
        width="200"
      >
        <template slot-scope="{row}">
          {{ $t(row.logType) }}
        </template>
      </el-table-column>
      <el-table-column prop="coin" :label="$t('NO_OF_COIN')" width="150" />
      <el-table-column :label="$t('IN_OUT')" width="150">
        <template slot-scope="{row}">
          {{ $t(row.transactionType) }}
        </template>
      </el-table-column>
      <el-table-column prop="balance" :label="$t('COIN_BALANCE')" width="150" />
    </el-table>

    <pagination
      v-loading="isLoading"
      v-show="total > 0"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      :total="total"
      @pagination="handleCurrentChange"
    />

  </div>
</template>

<script>
import { generalMixin } from '@/utils/general-mixin.js';
import TableActionRow from '@/components/table/table-action-row.vue'
import Pagination from '@/components/table/pagination.vue'
import cloneDeep from "lodash/cloneDeep"
import moment from 'moment'
import { PAGE_SIZES } from "@/constants";
import { getCoinList } from "@/api/coin"

const filterQuery = {
  page: 1,
  search: "",
  limit: PAGE_SIZES[0],
  coinLog: {} // empty obj as requested by BE
};

export default {
  name: "CoinHistory",
  mixins: [generalMixin],
  components: {
    Pagination, TableActionRow
  },
  data() {
    return {
      listQuery: cloneDeep(filterQuery),
      showSearch: false,
      isLoading: false,
      total: 0,
      // Data
      list: [],
      moment,
    }
  },
  computed: {
    searchKeyword: {
      get() { return this.listQuery.search },
      set(value) {
        this.listQuery.search = value
      }
    }
  },
  methods: {
    // REDIRECTION
    handleReset() {
      this.listQuery = cloneDeep(filterQuery)
      this.handleFilter();
    },
    toggleSearch() {
      this.showSearch = !this.showSearch
      if (this.showSearch == false) {
        this.searchKeyword = ""
        this.getList()
      }
    },
    handleCurrentChange(val) {
      this.listQuery.page = val.page
      this.listQuery.limit = val.limit
      this.getList()
    },
    getList() {
      // console.log('Sending: ', this.listQuery)
      this.isLoading = true
      getCoinList(this.listQuery)
      .then(res => {
        // console.log('Got: ', res)
        if (res.status != 'ok') return
        this.list = cloneDeep(res.result.list)
        this.total = res.result.total

        // Back to previous page if empty data page
        const page = res.result.page
        const finalPage = res.result.finalPage
        if (finalPage < page) {
          this.listQuery.page = finalPage;
          this.getList();
        }

      })
      .catch(this.handleError)
      .finally(() => this.isLoading = false)
    },
  },
  mounted() {
    this.getList()
  }
}
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";
@import "@/assets/style/mixin/table.scss";
#coin-history {
  position: relative;
  @include newsily-bo-table;
  .icon-copy {
    float: right;
  }
}
</style>